export const portfolioFilters = [
  'Брендинг',
  'Сайты',
  'Сервисы',
  'SEO',
  'Реклама',
  'SMM',
  'Контент',
  'm/Брендинг',
  'm/Сайты и сервисы',
  'm/Реклама, SEO',
  'm/Коммуникации'
];

export const portfolioSitesFeedKeys = [
  'akzent',
  'revenuelab',
  'gsom',
  //
  'ozon-santa',
  'netwell',
  'dins',
  //
  '6seasons_site',
  'luch',
  'heime',
  //
  'pizza-hut',
  'digitalpeaks',
  'dikom',
  //
  'gamedev',
  'versus',
  'copycat',
  //
  'wawelberg',
  'knauftherm',
  'saf'
];

export const protfolioFeedKeys = [
  'mxat',
  'school1',
  'nimax-kit',
  'mie',
  'relex',
  'melwin',
  'samolet',
  'okko',
  'global-net',
  'vkusvill',
  'garage8',
  'ineedchat',
  'dobroed',
  'belleyou',
  'simetra',
  'values',
  'quadcode',
  'lumene',
  'aag-site',
  'wawelberg',
  'offschool',
  'healthtech',
  'livedune',
  // 'bausch',
  'represocial',
  'boft',
  'dron',
  'netwell-game',
  'arcon',
  'vpohod',
  'letovo',
  'mollen',
  'core',
  'interleasing',
  'revenuelab',
  'knauftherm',
  'fitfashion',
  'gulliver',
  'fazer-tilda',
  'lineate',
  'garage-eight',
  'condenast-2',
  'yit-redimag',
  'comp-seti',
  'dins',
  'akzent',
  'uninova-seo-redesign',
  'softline-store',
  'smekalka',
  'uds',
  'mgeo',
  'ozon-santa',
  'softline-computers',
  'ati2',
  'gazpromneft-seo',
  'dobro',
  'revenue',
  'saf-tilda',
  'heime',
  'gsom',
  'benoit',
  '6seasons_site',
  'netwell',
  'luch',
  'gamedev',
  'versus',
  'msbaby',
  'uninova',
  'undatify',
  'copycat',
  'hqts',
  'private-banking',
  'bnews',
  'specia',
  'kaspersky',
  'future-health-design',
  'docklands',
  'taxovichkof',
  'bspb-special',
  'mkb',
  'ati',
  'uzhindoma',
  'mail',
  'tonika',
  'abbyy',
  'lan',
  'geek-picnic',
  'graal',
  'aist',
  'aag',
  'zigzag',
  'condenast',
  'pizza-hut',
  'tveda',
  'dosta',
  'emko',
  'yit',
  'inspireinvest',
  'bspb',
  'safeboard',
  'turbo',
  'mcrm',
  'psb',
  'aliance',
  'rdf',
  'wnog',
  'frankrg',
  'iport',
  'krauta',
  'svezhee',
  'zunami',
  'ff',
  'emex',
  'migrascope',
  'easia',
  'grotem',
  'biocad',
  'probalance',
  'pskb',
  'yota',
  'ibs',
  'resenergo',
  'digitalpeaks',
  'forabank',
  'dikom',
  'k29',
  'technopark',
  'cargo',
  'technopolis',
  'technosila',
  'krasnogorye',
  'losevo',
  'saf',
  'house-anatomy',
  'office-anatomy',
  'skazano-sdelano'

  // --- DISABLED ---
  // 'legenda',
  // 'princess'
  // 'fazer'
  // 'fazer-2',
  // 'fazer-house',
  // 'fazer-house-2',
  // 'gzprom-2',
  // 'gzprom-3',
  // 'gzprom-4',
  // 'gzprom-5',
  // 'gzprom-6',
  // 'gzprom-7',
  // 'light',
  // 'lumene-2',
  // 'svezy'
].map((i) => 'projects/' + i);

/**
 *
 * @param {*} type - string;
 * @param {*} props - Projects props;
 * @param {*} options ? {
 *      filters: string[];
 *      list: string[]; * for custom type
 *      etc...
 * }
 *
 * @returns string[]
 */
export function getFeedList(type, feed, options) {
  const result = [];

  for (let keyIndex = 0; keyIndex < protfolioFeedKeys.length; keyIndex++) {
    const feedKey = protfolioFeedKeys[keyIndex];
    const feedItem = feed[feedKey];
    result.push(feedItem);
  }

  if (options) {
    if (options.filters) {
      const optionFilters = options.filters;
      if (optionFilters.length === 0) return result.filter((i) => i);

      return result
        .filter((i) => i)
        .filter((item) => {
          if (!item.filters) return false;

          const itemFilters = item.filters.split(';').map((t) => t.trim().toLowerCase());
          const itemFiltersObj = {};
          for (let i = 0; i < itemFilters.length; i++) {
            const key = itemFilters[i];
            itemFiltersObj[key] = true;
          }

          const isItemHaveAllActiveFilters = (filter) => itemFiltersObj[filter.trim().toLowerCase()];
          return optionFilters.some(isItemHaveAllActiveFilters);
        });
    }
    if (options.tags) {
      const optionTags = options.tags;
      if (optionTags.length === 0) return result.filter((i) => i);
      return result
        .filter((i) => i)
        .filter((item) => {
          if (!item.tags) return false;

          const itemTags = item.tags.split(',').map((t) => t.trim().toLowerCase());
          const itemTagsObj = {};
          for (let i = 0; i < itemTags.length; i++) {
            const key = itemTags[i];
            itemTagsObj[key] = true;
          }
          const isItemHaveAllActiveTags = (tag) => itemTagsObj[tag.toLowerCase()];
          return optionTags.every(isItemHaveAllActiveTags);
        });
    }
  }

  return result.filter((i) => i);
}

export function getTagsListFromFeed(feed) {
  const result = []; // string[];

  for (let keyIndex = 0; keyIndex < feed.length; keyIndex++) {
    const itemTags = feed[keyIndex].tags;
    if (itemTags) {
      const tagsList = itemTags.split(',');

      tagsList.forEach((tag) => {
        result.push(tag.trim());
      });
    }
  }
  return [...new Set(result.map((i) => i.toLowerCase()))].sort();
}

export function getMoreId(filter) {
  if (filter) {
    if (filter === 'm/Брендинг' || filter === 'Брендинг') return 'more/branding-projects';
    if (filter === 'm/Сайты и сервисы' || filter === 'Сайты') return 'more/site-projects';
    if (filter === 'Сервисы') return 'more/site-projects';
    if (filter === 'm/Реклама, SEO' || filter === 'SEO' || filter === 'Реклама') return 'more/performance-projects';
    if (filter === 'Контент' || filter === 'm/Коммуникации' || filter === 'SMM') return 'more/media-projects';
    return undefined;
  } else {
    return undefined;
  }
}
