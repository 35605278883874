import React, { Component } from 'react';

import Video from 'elements/Video/Video';
import { classnames, classname } from 'utils';

import './Hello.css';

export class Hello extends Component {
  cl = classname('hello');

  render() {
    const { className, video } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('about')}>
            <h1 className={this.cl('title')}>
              Создаем
              <br style={{ display: 'block' }} />
              brand&nbsp;experience
              <br style={{ display: 'block' }} />
              для&nbsp;компаний будущего
            </h1>
            <div className={this.cl('descr', { desktop: true })}>
              Наша команда из&nbsp;100+ digital-специалистов
              <br style={{ display: 'block' }} />
              поможет вам создать прочные связи вашей компании
              <br style={{ display: 'block' }} />
              с&nbsp;клиентами, сотрудниками и&nbsp;партнерами.
            </div>
            <div className={this.cl('descr', { phone: true })}>
              Наша команда из&nbsp;100+ digital-специалистов поможет вам создать прочные связи вашей компании
              с&nbsp;клиентами, сотрудниками и&nbsp;партнерами.
            </div>
          </div>
          <div className={this.cl('services')}>
            <a href="/brands/" className={this.cl('link')}>
              Брендинг
            </a>
            <a href="/ux-ui/" className={this.cl('link')}>
              UX/UI-дизайн
            </a>
            <a href="/soft/" className={this.cl('link')}>
              Разработка
            </a>
            <a href="/performance/" className={this.cl('link')}>
              Реклама, SEO
            </a>
            <a href="/media/" className={this.cl('link')}>
              СММ
            </a>
            {/* <a
              href="http://nimax.school/?utm_source=main_page&utm_medium=banner&utm_campaign=menu_btn"
              className={this.cl('link')}
            >
              Образование
            </a> */}
          </div>
        </div>
        <div className={this.cl('bg')}>
          <Video autoplay={true} className={this.cl('video')} src={video} loop={true} />
        </div>
      </div>
    );
  }
}
