import React, { Component } from 'react';
import { classnames, classname } from 'utils';
import './About.css';

export class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOnDetails: ''
    };
    this.handleClick = this.handleClick.bind(this);
  }

  cl = classname('about-main');

  handleClick(selector) {
    if ((window.innerWidth || document.documentElement.clientWidth) < 1200) {
      if (this.state.isToggleOnDetails === selector) {
        this.setState(() => ({
          isToggleOnDetails: ''
        }));
      } else {
        this.setState(() => ({
          isToggleOnDetails: selector
        }));
      }
    }
  }

  render() {
    const { className } = this.props;
    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <div className={this.cl('title')}>
            Уникальное сочетание стратегического подхода и&nbsp;сильного производства
          </div>
          <div className={this.cl('list')}>
            <div className={this.cl('item')}>
              Создаем сильные стратегии, основанные на&nbsp;данных, и&nbsp;реализуем их&nbsp;во&nbsp;всех
              digital-каналах для создания консистентного и&nbsp;впечатляющего brand experience.
            </div>
          </div>
        </div>
        <div className={this.cl('links-overlay')} style={{ display: 'none' }}>
          <div
            className={
              this.cl('link-item') + ' ' + (this.state.isToggleOnDetails === 'strategy' && this.cl('link-item-active'))
            }
            onClick={() => this.handleClick('strategy')}
          >
            <h2
              className={
                this.cl('link-title') +
                ' ' +
                (this.state.isToggleOnDetails === 'strategy' && this.cl('link-title-active'))
              }
            >
              Стратегия
            </h2>
            <ol
              className={
                this.cl('details') + ' ' + (this.state.isToggleOnDetails === 'strategy' && this.cl('details-active'))
              }
            >
              <li>
                <a href="https://www.nimax.ru/ux-research/">Исследования</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/ux-audit/">UX-аудит</a>
              </li>
              <li>Аналитика</li>
              <li>Стратегические сессии</li>
              <li>
                <a href="https://www.nimax.ru/brand-platform/">Платформа бренда</a>
              </li>
              <li>Бренд-стратегия</li>
              <li>
                <a href="https://www.nimax.ru/media/">Коммуникационная стратегия</a>
              </li>
              <li>Контент-стратегия</li>
              <li>PR-стратегия</li>
              <li>Инфлюенс-стратегия</li>
              <li>Стратегия развития сервиса</li>
            </ol>
          </div>
          <div
            className={
              this.cl('link-item') + ' ' + (this.state.isToggleOnDetails === 'branding' && this.cl('link-item-active'))
            }
            onClick={() => this.handleClick('branding')}
          >
            <h2
              className={
                this.cl('link-title') +
                ' ' +
                (this.state.isToggleOnDetails === 'branding' && this.cl('link-title-active'))
              }
            >
              Брендинг
            </h2>
            <ol
              className={
                this.cl('details') + ' ' + (this.state.isToggleOnDetails === 'branding' && this.cl('details-active'))
              }
            >
              <li>
                <a href="https://www.nimax.ru/brands/">Брендинг</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/employer-branding/">HR-брендинг</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/naming/">Нейминг</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/corporate-identity/">Фирменный стиль</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/design-support/">Поддержка бренда</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/it-branding/">Брендинг для IT</a>
              </li>
            </ol>
          </div>
          <div
            className={
              this.cl('link-item') + ' ' + (this.state.isToggleOnDetails === 'marketing' && this.cl('link-item-active'))
            }
            onClick={() => this.handleClick('marketing')}
          >
            <h2
              className={
                this.cl('link-title') +
                ' ' +
                (this.state.isToggleOnDetails === 'marketing' && this.cl('link-title-active'))
              }
            >
              Маркетинг
            </h2>
            <ol
              className={
                this.cl('details') + ' ' + (this.state.isToggleOnDetails === 'marketing' && this.cl('details-active'))
              }
            >
              {/* <li>PR</li> */}
              <li>Управление трафиком</li>
              <li>
                <a href="https://www.nimax.ru/context/">Контекстная реклама</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/target/">Таргетированная реклама</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/seo/">SEO</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/smm/">СММ</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/influence/">Инфлюенс</a>
              </li>
              {/* <li><a href="https://www.nimax.ru/dashboards/">Сквозная аналитика</a></li> */}
              <li>
                <a href="https://www.nimax.ru/aso/">Креативная концепция</a>
              </li>
            </ol>
          </div>
          <div
            className={
              this.cl('link-item') +
              ' ' +
              (this.state.isToggleOnDetails === 'production' && this.cl('link-item-active'))
            }
            onClick={() => this.handleClick('production')}
          >
            <h2
              className={
                this.cl('link-title') +
                ' ' +
                (this.state.isToggleOnDetails === 'production' && this.cl('link-title-active'))
              }
            >
              Продакшен
            </h2>
            <ol
              className={
                this.cl('details') + ' ' + (this.state.isToggleOnDetails === 'production' && this.cl('details-active'))
              }
            >
              <li>
                <a href="https://www.nimax.ru/ux-ui/">UX/UI</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/corp/">Сайты и сервисы</a>
              </li>
              <li>
                <a href="https://no-code.nimax.ru/">Сайты на Nocode-решениях</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/content/">Контент</a>
              </li>
              <li>
                <a href="https://www.nimax.ru/chat-bots/">Чат-боты</a>
              </li>
              <li>Фото и видео</li>
              <li>Спецпроекты</li>
              <li>Креативные рассылки</li>
            </ol>
          </div>
          <div
            className={
              this.cl('link-item') + ' ' + (this.state.isToggleOnDetails === 'education' && this.cl('link-item-active'))
            }
            onClick={() => this.handleClick('education')}
          >
            <h2
              className={
                this.cl('link-title') +
                ' ' +
                (this.state.isToggleOnDetails === 'education' && this.cl('link-title-active'))
              }
            >
              Образование
            </h2>
            <ol
              className={
                this.cl('details') + ' ' + (this.state.isToggleOnDetails === 'education' && this.cl('details-active'))
              }
            >
              <li>Онлайн-курсы</li>
              <li>Корпоративное обучение </li>
              <li>Образовательные проекты</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
