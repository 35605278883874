import React from 'react';
import { Phone, Email, Icon } from 'elements';

const Contact = ({ item, cl }) => {
  switch (item?.type) {
    case 'link':
      return (
        <a href={item.link} target={'_blank'} className={cl('social')}>
          <Icon name={item.value} size={1} className={item.move ? 'move' : ''} />
          {item.text}
        </a>
      );
    case 'phone':
      return (
        <Phone phone={'+7 (812) 385-52-06'}>
          {(phone) => (
            <a href={'tel:' + phone} className={cl('phone')}>
              {phone}
            </a>
          )}
        </Phone>
      );
    case 'email':
      return (
        <Email email={'hello@nimax.ru'}>
          {(email) => (
            <a href={`mailto:${email}`} className={cl('email')}>
              {email}
            </a>
          )}
        </Email>
      );
    default:
      return null;
  }
};

export default Contact;
