import React, { Component } from 'react';
import { Match } from '@reach/router';
import Headroom from 'headroom.js';

import Button from 'elements/Button/Button';
import Icon from 'elements/Icon/Icon';
import { classnames, classname, back } from 'utils';

import './Header.css';
import tokens from './Header.tokens';

export class Header extends Component {
  cl = classname('header');

  static defaultProps = {
    theme: 'dark'
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!process.browser) return;

    this.headroom = new Headroom(this.block, {
      classes: {
        unpinned: 'header_unpinned'
      },
      offset: 100,
      tolerance: {
        up: 5,
        down: 0
      }
    });
    this.headroom.init();
  }

  render() {
    const { className, theme, headerColor } = this.props;

    const bgChanger = (headerColor = 'dark') => {
      if (typeof document !== 'undefined') {
        const body = document.querySelector('body');
        if (headerColor === 'light') {
          body.classList.add('app__bg-light');
        } else {
          body.classList.remove('app__bg-light');
        }
      }
    };

    const classes = classnames(
      className,
      this.cl({ theme }),
      headerColor === 'light' ? 'header_color_light' : 'header_color_dark'
    );

    bgChanger(headerColor);

    return (
      <header className={classes} ref={(node) => (this.block = node)}>
        {this.props.logo !== false && (
          <a href="/" className={this.cl('logo')}>
            <img
              src={`/logo/${headerColor === 'light' ? 'logo_dark.svg' : 'logo_light.svg'}`}
              alt="Digital-агентство Nimax"
            />
          </a>
        )}
        {this.props.type !== 'light' && (
          <nav className={this.cl('group', { nav: true })}>
            {tokens.map((item) => {
              return !item.children ? (
                <a href={item.link} key={item.name} className={classnames(this.cl('item'), this.cl('link'))}>
                  {item.name}
                </a>
              ) : (
                <div
                  className={`${this.cl('item')} ${item.children.some((child) => child.subMenu) ? 'is-sub' : ''}`}
                  key={item.name}
                >
                  <div className={this.cl('link')}>
                    <div className={this.cl('name')}>
                      {item.name}
                      <Icon name={'arrow'} className={this.cl('arrow')} />
                    </div>
                  </div>
                  <div
                    className={`${this.cl('children')} ${item.children.some((child) => child.subMenu) ? 'is-sub' : ''}`}
                  >
                    {item.children.map((child) =>
                      child.subMenu ? (
                        <div key={child.name} className={classnames('header__children-item')}>
                          <a href={child.link} key={child.name}>
                            <div>{child.name}</div>
                            <small>{child.desc}</small>
                            {child.subMenu && <Icon name={'arrow'} className={this.cl('arrow')} />}
                          </a>
                          {child.subMenu && (
                            <div className={this.cl('sub-menu')}>
                              {child.subMenu.map((subMenuItem) => (
                                <a href={subMenuItem.link} key={subMenuItem.name}>
                                  {subMenuItem.name}
                                </a>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <a href={child.link} key={child.name}>
                          <div>{child.name}</div>
                          {child.desc && <small>{child.desc}</small>}
                        </a>
                      )
                    )}
                  </div>
                </div>
              );
            })}
          </nav>
        )}
        <Match path={'*'}>
          {({ match }) => {
            const isOrder = ['order', 'menu'].includes(match['*']);
            const isOrderSuccess = ['order-success'].includes(match['*']);

            if (isOrder) {
              return (
                <>
                  {/* <div className={this.cl('lang')}>
                    <span className={this.cl('lang-item')}>ru</span>
                    <a
                      target="_self"
                      className={classnames(this.cl('lang-item'), this.cl('lang-link'))}
                      href={'https://www.nimax.agency/'}
                    >
                      en
                    </a>
                  </div> */}
                  <button type={'button'} className={this.cl('close')} onClick={() => back()}>
                    <Icon name={'cancel'} />
                  </button>
                </>
              );
            } else if (isOrderSuccess) {
              return (
                <>
                  <button type={'button'} className={this.cl('close')} onClick={() => back()}>
                    <Icon name={'cancel'} />
                  </button>
                </>
              );
            } else {
              return (
                <>
                  <div className={this.cl('group', { contacts: true })}>
                    {/* <div className={this.cl('lang')}>
                      <span className={this.cl('lang-item')}>ru</span>
                      <a
                        target="_self"
                        className={classnames(this.cl('lang-item'), this.cl('lang-link'))}
                        href={'https://www.nimax.agency/'}
                      >
                        en
                      </a>
                    </div> */}
                    {/*<a href={'/contacts/'} className={classnames(this.cl('item'), this.cl('link'))}>*/}
                    {/*  Контакты*/}
                    {/*</a>*/}
                    <div className={this.cl('item')}>
                      <Button url={'/order/'}>Обсудить проект</Button>
                    </div>
                  </div>
                  <a href="/menu/" className={classnames(this.cl('burger'), this.cl('link'))}>
                    <Icon name={'burger'} />
                  </a>
                </>
              );
            }
          }}
        </Match>
      </header>
    );
  }
}
